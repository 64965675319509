export default function programacaoApi(inHome = false){

    const listDatas = document.querySelectorAll('.lista-datas-js');
    const programacoesWrapper = document.querySelector('.programacoes');
    const programacaoWrapperHome = document.querySelector('.programacao-tab-content .grid-wrapper');
    // const pageProgramacaoInfantil = document.querySelector('.programacao-infantil')

    // console.log('Page', pageProgramacaoInfantil);

    function compararPorHorario(evento1, evento2) {
      const horario1 = evento1.info.horario;
      const horario2 = evento2.info.horario;
  
      return horario1.localeCompare(horario2);
  }



    function renderProgramacao(data){

        if(!data) return
        const sortedProgramacao = data.sort(compararPorHorario)

        const programacaoHTML = sortedProgramacao.map(item => {

            return `
            <div class="programacao">
                <div class="horario-wrapper">
                <p>   às ${item.info.horario.split(':')[0]}<span class="horario-h">h</span>  </p>
                ${item.info.local ? `    
                <span>-</span>           
                <p class="local"> ${item.info.local}</p>
 
                ` : ''}
                </div>     
             
            
            <div class="dia-atracoes-wrapper">

                <div class="jornada-wrapper">
                    <h2 class="jornada-title"> ${item.info.titulo}</h2>
                    <div class="content-for-p">

                   
                        <p> ${item.info.descricao}</p>

                        <p class="continuar-lendo">
                          Continuar lendo 
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99967 6.66699V13.3337" stroke="#D6DF21" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.3337 9.99967H6.66699" stroke="#D6DF21" stroke-linecap="round" stroke-linejoin="round"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 17.5V17.5C5.8575 17.5 2.5 14.1425 2.5 10V10C2.5 5.8575 5.8575 2.5 10 2.5V2.5C14.1425 2.5 17.5 5.8575 17.5 10V10C17.5 14.1425 14.1425 17.5 10 17.5Z" stroke="#D6DF21" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            
                        </p>
                    </div>
                    
                </div>
                <div class="header-atracoes">
                  <p class="header-atracoes-titulo">Atrações</p>
                  <div class="swiper-controls-header">

                    <div class="pagination"></div>
                    <div class="buttons">
                      <svg class="mobile-swiper-icon">
                        <use xlink:href="#icon_mobile-swiper"></use>
                      </svg> 
                      <div class="button button-left">
                        <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.23828 6.28851L0.76125 3.81248L3.23828 1.33645" stroke="#E2E8ED"
                            stroke-linecap="round" stroke-linejoin="round" />
                        </svg>


                      </div>
                      <div class="button button-right">
                        <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.760742 1.33643L3.23777 3.81246L0.760742 6.28849" stroke="#0F0F12"
                            stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="swiper swiper-programacao-atracoes">
                  <div class="swiper-wrapper">

                  ${item.data_atracoes.map(atracao =>{

                    return `
                        <div class="swiper-slide">
                            <div class="card-programacao card-evento open-modal-atracao" data-id="${atracao.id}">
                            <div class="card-evento-img">
                                <img src="${atracao.imagem}" alt="${atracao.nome}">
                            </div>
                            <a href="#" class="content">
                                <h2 class="card-evento-title">${atracao.nome}</h2>
                                <p class="card-evento-role">${atracao.funcao}</p>

                            </a>
                            </div>
                        </div>
                    `;
                  }).join('')} 
                  </div>
                </div>
            </div>
          </div>
            `;
        }).join('');

        if(programacoesWrapper){
          programacoesWrapper.innerHTML = programacaoHTML;
        }

        const  swiperProgramacaoAtracoes = new Swiper('.swiper-programacao-atracoes',{
            slidesPerView: 1.6,
            spaceBetween: 8,
            
        
            breakpoints:{
              768:{
                slidesPerView: 3.8,
              },
              1440:{
                slidesPerView: 4.5,
              },
            },
            pagination: {
              el: '.swiper-programacao-atracoes .swiper-pagination'
            },
            navigation: {
              nextEl: '.dia-atracoes-wrapper .button-right',
              prevEl: '.dia-atracoes-wrapper .button-left',
            },
            
        });
    };


    function renderProgramacaoInHome(data){
      if(!data) return 
       

      const sortedProgramacao = data.sort(compararPorHorario)

      const programacaoHTML = data.map(item => {



        // /static/front_assets/src/assets/imagens/carpa.webp
        return `
        <div class="side-info">

        <div class="content-side">
          <div class="img-detail">
          
            <img src="static/front_assets/src/assets/imagens/icons/flor-dali-xl.png" alt="">
          </div>
          <h2 class="programacao-horario">
            Às ${item.info.horario} 
          </h2>
          <h2 class="programacao-local">
          
          ${item.info.local}
          </h2>
        </div>


        <div class="bottom-info">
          <a href="/programacao" class="button-bottom-info">
            VER PROGRAMAÇÃO COMPLETA
            <svg>
              <use xlink:href="#icon_diag-black"> </use>
            </svg>
          </a>
        </div>

      </div>
      <div class="programacao-dia-wrapper">

        <div class="dia-info-wrapper">
          <div class="title">${item.info.titulo} </div>
          <div class="content-for-p"> 
            <p class="descricao ">
              ${item.info.descricao}
            </p>
            <p class="continuar-lendo">
              Continuar lendo

              <svg>
                <use xlink:href="#icon_plus"></use>
              </svg>

            </p>
          </div> 
        </div>
        <div class="dia-atracoes-wrapper">
          <div class="header-atracoes">
            <p class="header-atracoes-titulo">Atrações</p>
            <div class="swiper-controls-header">

              <div class="pagination"></div>
              <div class="buttons">
                <div class="button button-left">
                  <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.23828 6.28851L0.76125 3.81248L3.23828 1.33645" stroke="#E2E8ED"
                      stroke-linecap="round" stroke-linejoin="round" />
                  </svg>


                </div>
                <div class="button button-right">
                  <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.760742 1.33643L3.23777 3.81246L0.760742 6.28849" stroke="#0F0F12"
                      stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper swiper-programacao-atracoes">
            <div class="swiper-wrapper">
            ${item.data_atracoes.map(atracao =>{

              return `
              
                  <div class="swiper-slide">
                      <div class="card-programacao card-evento open-modal-atracao" data-id="${atracao.id}">
                      <div class="card-evento-img">
                          <img src="${atracao.imagem}" alt="${atracao.nome}">
                      </div>
                      <a href="#" class="content">
                          <h2 class="card-evento-title">${atracao.nome}</h2>
                          <p class="card-evento-role">${atracao.funcao}</p>

                      </a>
                      </div>
                  </div>
              `;
            }).join('')} 

             
            </div>
          </div>
        </div>
      </div>
        `
      }).join('');

      if(programacaoWrapperHome){
       
        programacaoWrapperHome.innerHTML = programacaoHTML;
      }

      const  swiperProgramacaoAtracoes = new Swiper('.swiper-programacao-atracoes',{
        slidesPerView: 1.3,
        spaceBetween: 8,
  
        breakpoints:{
          768:{
            slidesPerView: 3,
          }
        },
    
        navigation: {
          nextEl: '.dia-atracoes-wrapper .button-right',
          prevEl: '.dia-atracoes-wrapper .button-left',
        },
        
      });

    }


  function getProgramacao(data, espaco= ''){

    console.log('DATA ',data)
    console.log('espaco', espaco)
      if(!data) return

      // let url = ''
      //   if(pageProgramacaoInfantil)
      //     url = `/home/api/programacao-dia/?espaco=Infantil&data=${data}`
      //   else
        const  url = `/home/api/programacao-dia/?data=${data}&espaco=${espaco}`
      //const url = `/home/api/programacao-dia/?data=${data}&espaco=${espaco}`
      //const url = `/home/api/programacao-dia/?espaco=Infantil&data=${data}`
      axios.post(url)
          .then((response) => {

              
              if(response.data){
                  
                
                if(inHome){
                  renderProgramacaoInHome(response.data);
                }else{
                  renderProgramacao(response.data)
                }
              }
          })
          .catch((response) => {
              console.log(response)
              
          });
  }



    listDatas && listDatas.forEach(data => {
        data.addEventListener('click', (e) => {
            const target = e.target.closest('.lista-datas-js')
            if(!target) return
            listDatas.forEach(i => i.classList.remove('active'))
            target.classList.add('active')
            const data = target.dataset.diaEvento
            
            const selectLabel = document.querySelector('.selected-espaco')
            const espaco = selectLabel.dataset.espacoSelecionado
            const novaURL = `${window.location.origin}${window.location.pathname}?espaco=${espaco}&data=${data}`;
            const urlInfantil = `${window.location.origin}${window.location.pathname}?espaco=Infantil&data=${data}`

            // if (pageProgramacaoInfantil){
            //     console.log('url infantil', urlInfantil);
            //     window.history.pushState({}, '', urlInfantil); 
            //     getProgramacao(data , espaco);
            // } else if (!pageProgramacaoInfantil){
            window.history.pushState({}, '', novaURL); 
            getProgramacao(data , espaco);
        })
    })

    const primeiraData = document.querySelector('.lista-datas-js')
    const urlParams = new URLSearchParams(window.location.search);
  
    const espaco = urlParams.get('espaco');
    const dataParam = urlParams.get('data');

    if(primeiraData){

      const data = primeiraData.dataset.diaEvento
      listDatas.forEach((item) => {
        const data = item.dataset.diaEvento
        console.log(data, 'A')
        if(dataParam == data){
          
          listDatas.forEach((item) => {
            item.classList.remove('active')
          });
          item.classList.add('active');
        }

      });
        if(espaco){
          const selectLabel = document.querySelector('.selected-espaco')
          selectLabel.dataset.espacoSelecionado = espaco;
          const itemLabel = document.querySelector(`.espaco-item[data-espaco='${espaco}']`);
          selectLabel.innerHTML = itemLabel?.innerText
        }  
      
        if (dataParam && espaco) {
          getProgramacao(dataParam, espaco);
        } else if(dataParam){
          getProgramacao(dataParam)
        } else {
          getProgramacao(data);
        }
        console.log(espaco,dataParam, data)
    }
    
  




    const filtrarPorEspaco = document.querySelector('.filtrar-por-espaco');
    const espacos = document.querySelector('.espacos');
    const setaPesquisa = document.querySelector('.svg-pesquisa')
    const selectLabel = document.querySelector('.selected-espaco')

    if(filtrarPorEspaco){
      filtrarPorEspaco.addEventListener('click', () =>{
        espacos.classList.toggle('open');
        setaPesquisa.classList.toggle('active');
  
      })
  
      const items = document.querySelectorAll('.espaco-item')
  
      items.forEach(option => {
       option.addEventListener('click', () => {
           selectLabel.innerHTML = option.innerText;
         

           const espacoQuery = option.dataset.espaco;
           selectLabel.dataset.espacoSelecionado = espacoQuery;
        // console.log(espacoQuery)
    
        const dataElAtivo = document.querySelector('.lista-datas-js.active')
        const dataAtiva = dataElAtivo.dataset.diaEvento
        const novaURL = `${window.location.origin}${window.location.pathname}?espaco=${espacoQuery}&data=${dataAtiva}`;
          window.history.pushState({}, '', novaURL);
           getProgramacao(dataAtiva, espacoQuery)
           espacos.classList.remove('open');
          setaPesquisa.classList.remove('active');
          
        
         });
      })
    }
    
}