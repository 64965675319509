export default function animationHome(){


function floatingGirl(){
    const animation = gsap.to('.floating-girl img', {
      y: 10,
      duration: 2,
      yoyo: true,
      repeat: -1,
      ease: 'none',
    });
}

    function gatinhoEnave(){
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#atracoes",
                start: "top-=150px 90%", 
                end: "120px 70%", 
                
                scrub: 1
              },
            defaults:{ opacity: 0, ease: "none"}});

          tl
          .from('#o-evento .gatinho-orna  ',  { opacity: 1,  y: 130, duration:1.5})


          gsap.from('#o-evento .alien-orna ', {

            scrollTrigger:{
                trigger: "#atracoes",
                start: "top-=150px 80%",
                end: "120px 20%", 
                
               
                scrub:2
            },
            x: -300,
            opacity:1
          })
          

    }

    function bannerPatrocinio(){
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#banner-patrocinio",
                start: "top+=50px 80%",
              
                // scrub:1
              },
            defaults:{ opacity: 1, ease: "none"}});

            tl.from('#banner-patrocinio .robo-wrapper',{
               y: 200,              
            })
    }

    function contato(){
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#contato-footer",
                start: "top+=50px 80%",
              
                scrub:2
              },
            defaults:{ opacity: 1, ease: "none"}});
            // gsap.set('#contato-footer .contato-girl-orna',{ y: -100})

            tl.from('#contato-footer .contato-girl-orna', {
                y: -100
            })
    }



    gatinhoEnave();
    bannerPatrocinio();
    contato();
    floatingGirl();
}