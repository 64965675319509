export default function modalAtracoes() {
  const modal = document.querySelector("#modal-atracoes");
  const modalOverlay = document.querySelector("#modal-overlay");
  const closeButton = document.querySelector("#close-button");
  const openButton = document.querySelectorAll(".open-modal-atracao");


  const mainWrapper = document.querySelector('main');

  const oBody = document.body

  if ( modal && modalOverlay && openButton && closeButton) {
    closeButton.addEventListener("click", function () {
      modal.classList.toggle("closed");
      modalOverlay.classList.toggle("closed");
      oBody.classList.toggle('modal-open')
    });



    mainWrapper.addEventListener('click', (e) => {
      const target = e.target.closest('.open-modal-atracao');
      // console.log(target)
      if(!target) return
      modal.classList.toggle("closed");
      modalOverlay.classList.toggle("closed");
      oBody.classList.toggle('modal-open')
  })

    // openButton.forEach(btn => {
    //     btn.addEventListener("click", function () {
    //         modal.classList.toggle("closed");
    //         modalOverlay.classList.toggle("closed");
    //         oBody.classList.toggle('modal-open')
    //     });

    // }) 


    modalOverlay.addEventListener('click', (e) => {
        modal.classList.add("closed");
        modalOverlay.classList.add("closed");
        oBody.classList.toggle('modal-open')
    })

   
  }
}
