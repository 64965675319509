
export default function menu() {
    const btnMobile = document.getElementById('btn-mobile');
    const headerEl = document.getElementById('header');

    const isHome = document.querySelector('.body-home');
    const cepCnpj = document.querySelector('#cep-cnpj-js');

    const formMenu = document.querySelectorAll('.form-menu');
    const linksMenu = document.querySelectorAll('#menu a.link');

  


 



    function toggleMenu(event) {
       if(event.type === 'touchstart') event.preventDefault();
       const nav = document.getElementById('nav');
       
       document.body.classList.toggle('menu--open')
       nav.classList.toggle('active');
       const active = nav.classList.contains('active');
       event.currentTarget.setAttribute('aria-expanded', active);
   
       if (active) {
           event.currentTarget.setAttribute('aria-label', 'Fechar menu')
       } else {
           event.currentTarget.setAttribute('aria-label', 'Abrir menu')
       } 
   }

   linksMenu.forEach(link => {
        link.addEventListener('click', ()=>{
            const nav = document.getElementById('nav');
            nav.classList.remove('active');
            document.body.classList.remove('menu--open')
        })
   })


//    console.log(gsap)


   if(btnMobile){
    btnMobile.addEventListener('click', toggleMenu);
    if(headerEl){  
        if(isHome){
            window.addEventListener("scroll", function () {
                headerEl.classList.toggle("sticky", window.scrollY >  150);
            });
        }else{
            headerEl.classList.add('sticky');
            headerEl.classList.add('is-not-home')
        }
    }
   }
   

}