export default function homejs(){
    



    // TABS PROGRAMACAO 
    const allTabs = document.querySelector('.swiper-programacao-tabs ') 
    const allTabsItem = document.querySelectorAll('.swiper-programacao-tabs .tab-programacao ') 
    const tabContents = document.querySelectorAll('.programacao-tab-content')




    if(allTabs){
        allTabs.addEventListener('click', (e)=>{
          const tab = e.target.closest('.tab-programacao');
          if(!tab) return
          console.log(tab)
          allTabsItem.forEach(item => item.classList.remove('active'));

          tab.classList.add('active')
          const tabId = tab.dataset.content;

          tabContents.forEach(t => t.classList.remove('active'))
          const contentToShow = document.querySelector(`#${tabId}`)
          console.log(tabId)
          contentToShow.classList.add('active')
        })
    }



    // readmore programacao 
    
    const readmore = document.querySelectorAll('.continuar-lendo')

    readmore.forEach(item => {
      item.addEventListener('click',(e) => {
        const parent = e.target.closest('.dia-info-wrapper')
        if(parent){
          parent.classList.toggle('show')
        }
      })
    })


     
   


    const  swipertabs = new Swiper('.swiper-programacao-tabs',{
        slidesPerView: 'auto',
        spaceBetween: 8,
       
      });
    
    const  swiperProgramacaoAtracoes = new Swiper('.swiper-programacao-atracoes',{
      slidesPerView: 1.3,
      spaceBetween: 8,

      breakpoints:{
        768:{
          slidesPerView: 3,
        }
      },
  
      navigation: {
        nextEl: '.dia-atracoes-wrapper .button-right',
        prevEl: '.dia-atracoes-wrapper .button-left',
      },
      
    });


    const swiperMidia = new Swiper('.swiper-midia',{
      slidesPerView: 1.2,
      spaceBetween: 16
    });

    if (window.innerWidth >= 768) {
      // Destroy the Swiper instance
      swiperMidia.destroy();
    }

    
const mql1024 = window.matchMedia('(max-width:1024px)').matches
    const swiperYoutube = new Swiper('.swiper-youtube',{
      slidesPerView: 1.2,
      spaceBetween: 16,

      navigation: {
        nextEl: '#youtube .button-right',
        prevEl: '#youtube .button-left',
      },
      pagination:{
        el:  '#youtube .controls .swiper-pagination' ,
        dynamicBullets: true,
        clickable: true
      },

      breakpoints:{
        600:{
          slidesPerView: 2.2,
        },
        768:{
          slidesPerView: 2.2,
          spaceBetween: 16,
        },
        1024:{
          slidesPerView: 3,
          spaceBetween: 32,
        }
      }
    })





    // insert content instagram



    

}